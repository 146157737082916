import { Test1, TrainingData } from "data/trainingData";
import React, { useEffect, useState } from "react";
import { useMobile } from "utils/CustomHooks/useDeviceResponsive";
import Group132 from "assets/images/Group-132.png";
import Header from "components/Header/header";
import Group119 from "assets/images/Group-119.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const CoursePage = () => {
  const isMobile = useMobile();
  const [data, setData] = useState({});

  let { pathname } = useLocation();

  useEffect(() => {
    const meta_data = TrainingData?.find(
      (item) =>
        item.route === pathname.replace("training", "").replace(/\//g, "")
    );
    setData(meta_data);
  }, [pathname]);

  return (
    <div>
      <section className="Trainings-pricing-Stsection">
        <div className="container">
          <div className="row d-flex justify-content-center flex-wrap">
            <div className="row">
              <div className="col-md-6" style={{ padding: "30px" }}>
                <img src={data.img}></img>
              </div>
              <div className="col-md-6 " style={{ padding: "50px 0px" }}>
                <div className="pricing-col px-4">
                  <div className={!isMobile ? "days" : "days mx-2"}>
                    <p>
                      {data?.days == 1
                        ? data?.days + " Day"
                        : data?.days + " Days"}
                    </p>
                  </div>
                  <div className="row">
                    <div
                      className="col-sm-2 text-center"
                      style={{ padding: "0px" }}
                    >
                      <img
                        src={data.icon}
                        style={{ height: isMobile ? "50px" : "40px" }}
                      />
                    </div>
                    <div className="col-sm-8" style={{ padding: "0px" }}>
                      <h5>{data?.title}</h5>
                      {/* <Helmet>
                        <title>{data?.title}</title>
                      </Helmet> */}

                      <Helmet>
                        {/* General Meta Tags */}
                        <title>{data?.title}</title>
                        <meta name="description" content={data?.heading} />

                        {/* Open Graph Meta Tags for Facebook, Instagram, Pinterest */}
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={data?.title} />
                        <meta
                          property="og:description"
                          content={data?.heading}
                        />
                        <meta
                          property="og:image"
                          content={data?.img}
                        />
                        <meta property="og:url" content={data?.url} />
                        <meta property="og:site_name" content="YourSiteName" />

                        {/* Twitter Card Meta Tags */}
                        <meta
                          name="twitter:card"
                          content="summary_large_image"
                        />
                        <meta name="twitter:title" content={data?.title} />
                        <meta
                          name="twitter:description"
                          content={data?.heading}
                        />
                        <meta
                          name="twitter:image"
                          content={data?.img}
                        />
                        <meta
                          name="twitter:site"
                          content="@YourTwitterHandle"
                        />
                        <meta
                          name="twitter:creator"
                          content="@YourTwitterHandle"
                        />

                        {/* Pinterest Meta Tag */}
                        <meta name="pinterest-rich-pin" content="true" />
                      </Helmet>
                    </div>
                    <div className="price">
                      {/* <p>{data?.price}</p> */}
                      <p
                  style={{ padding: "0px" }}
                  dangerouslySetInnerHTML={{ __html: data?.price }}
                />
                    </div>
                    <div className="text-inner">
                      <p>{data?.heading}</p>
                    </div>
                    {/* <div className="BookNow">
                      <a href="/trainings-form" className="btn" role="button">
                        {" "}
                        Book Now&nbsp;<span>➜</span>
                      </a>
                    </div> */}
                    <div className="row row-1" style={{  bottom: "0px",  width: "100%",  zIndex: "1", height:"54px"
                      
                     }}>
                        <div className="col-sm-4">
                          <div className="BookNow123" style={{ width: "100%" }}>
                            <a
                              href="/training-form"
                              className="btn"
                              role="button"
                            >
                              {" "}
                              Book Now&nbsp;<span>➜</span>
                            </a>
                          </div>
                        </div>
                        {/* {item?.pdf_url && (
                        <div
                          className="col-sm-4 BookNow1-1"
                          style={{
                            textAlign: "end",
                            width: "38%",
                            padding: "0px",
                          }}
                        >
                          <a href={item?.pdf_url} className="btn" role="button">
                            2025 Assessment Timetable
                          </a>
                        </div>
                      )} */}

{ data?.pdf_url && <div className="col-sm-4" style={{
                           padding: "0px",
                         }} >
                          <div className="BookNow123" style={{
                           
                            width: "100%",
                            padding: "0px",
                          }} >
                            <a href={data?.pdf_url} className="btn" role="button" style={{
                           paddingRight: "0px",
                           paddingLeft: "0px",
                         }} >
                              {" "}
                              2025 Assessment Timetable
                            </a>
                          </div>
                        </div>}




                        { data?.schedule_url && <div className="col-sm-4">
                          <div className="BookNow123" style={{ textAlign: "end" }} >
                            <a href={data?.schedule_url} className="btn" role="button">
                              {" "}
                              Schedule &nbsp;<span>➜</span>
                            </a>
                          </div>
                        </div>}
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoursePage;
