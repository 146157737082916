import React, { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import HomeImg from "assets/images/Home.png";
import Contact from "assets/images/Safety-in-the-Workplace-banner.png";
import ConsultancyImg from "assets/images/Consultancy.png";
import TrainingImg from "assets/images/Training.png";
// import WhyAreWe from "assets/images/Why-Are-We-Different-From-Others (1).";
import { useMobile } from "utils/CustomHooks/useDeviceResponsive";
import Skeleton from "components/Skeleton/Skeleton";
import "animate.css";
import Navbars from "./Navbar";
const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const isMobile = useMobile();
  const animate_ref = useRef(null);
  useEffect(() => {
    animate_ref?.current?.classList.add("animate__backInLeft");
    return () => animate_ref?.current?.classList.remove("animate__backInLeft");
  }, [location]);

  return (
    <div>
      <section className="Customised-Stsection position-relative">
        <img
          src={
            pathname == "/"
              ? HomeImg
              : pathname == "/consultancy"
              ? ConsultancyImg
              : pathname == "/training"
              ? TrainingImg
              : Contact
          }
          // style={{ width: "100% !im" }}
        />
        <Navbars />

        <div className="container ">
          <div className="row">
            <div
              ref={animate_ref}
              className={
                isMobile
                  ? "col-md-4"
                  : "col-md-5 header-text-top animate__animated "
              }
            >
              <h1 className="fade-right1 ">
                <span style={{ color: "#3A47BC" }}>
                  Customised, <br />
                  Multilingual MS BLUE
                </span>
                <br />
                <span style={{ color: "#67D3D1" }}>Training Courses</span>
              </h1>
              <p>
                Provided both face-to-face and online can help you get the most
                out of your staff. For any company to operate effectively,
                training and CPD maintenance are necessary. For both new and
                seasoned operators, MS BLUE will provide the most recent and
                pertinent Training Packages.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
