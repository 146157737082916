import { Helmet } from "react-helmet";
import {
  CiltatmCpcCourseScheduleinhtml,
  CiltatmCpcCourseScheduleinhtmlMobile,
} from "./CiltatmCpcCourseSchedule";
import Header from "components/Header/header";
import CMILTLogo from "assets/images/CMILT-Logo.png";
import "pages/Schedule/Schedule.css";

export default function CiltatmCpcCourseSchedule() {
  return (
    <>
      <Helmet>
        <title>CILT Accredited Transport Manager CPC Course Schedule</title>
      </Helmet>
      <Header />
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-3" style={{ padding: "0% 2%" }}>
              <img src={CMILTLogo} alt="CMILT Logo" />
            </div>
            <div className="col-md-9" style={{ paddingLeft: "2%" }}>
              <div className="Schedule-heading">
                <h1>
                  CILT Accredited Transport Manager CPC Course
                  <br />
                  (9 Days) English and Romanian*
                </h1>
                <p>
                  <span
                    style={{
                      color: "#808080",
                      fontWeight: "600",
                      fontSize: "13px",
                    }}
                  >
                    *exams held in English only
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#e02b20",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Courses start from 8am daily.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 desktop1" style={{ padding: "0px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: CiltatmCpcCourseScheduleinhtml,
                }}
              ></div>
            </div>
            <div className="col-lg-12 Mobile" style={{ padding: "0px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: CiltatmCpcCourseScheduleinhtmlMobile,
                }}
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="schedule-test" style={{ padding: "30px 0px" }}>
                <p>
                  This session is priced at £1495 for 09 days (1 through 09) +
                  VAT.
                </p>
                <p>
                  Dates marked ** are additional days and, where offered, will
                  be arranged at the discretion and availability of trainers as
                  virtual or in-person sessions.
                </p>
                <p>
                  Examination fees is £325 + VAT and these are not included in
                  the course price.
                </p>
                <p>
                  Resits and revisions for resits will also be charged
                  separately.
                </p>
                <p>
                  Examination fees are liable to change as per accrediting body
                  uplifts and additional costs.
                </p>
                <p>
                  The sessions will be held online, and the examination will be
                  conducted paper-based in London (Greys) or online. For more
                  details or to book, please contact your local MS Blue Centre
                  or visit:
                </p>
                {/* <a
                  href="/contact-us"
                  className="btn mb-5"
                  role="button"
                  style={{
                    // backgroundColor: "#3a47bc",
                    color: "#3a47bc",
                    borderRadius: "30px",
                  }}
                >
                  https://msblue.uk/contact
                </a> */}
                <a
                  href="/training-form"
                  // className="btn"
                  // role="button"
                
                  style={{
                    // backgroundColor: "#3a47bc",
                    color: "#3a47bc",
                    borderRadius: "30px",
                    paddingtop:"20px"
                  }}
                >
                  https://msblue.uk/training-form
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
